import "./ButtonCard.css";
import React, { useState } from "react";
import ButtonPopup from "../ButtonPopup/ButtonPopup.jsx";

const ButtonCard = ({ button, handleUpdateButton, handleCreateButton, handleDeleteButton }) => {
  const { category, name, script, argument, icon, htmlClass } = button;

  const urlSegments = window.location.pathname.split("/");
  const lastSegment = urlSegments[urlSegments.length - 1];

  const [isPopupOpen, setPopupOpen] = useState(false);
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  return (
    <div className="button-card-wrapper">
      <li className="button-card" onClick={togglePopup}>
        <div className="button-card__main">
          <div>
          <div dangerouslySetInnerHTML={{ __html: icon }} />
          </div>
          <h4 className="button-card__title">{name}</h4>
        </div>
      </li>
      {/* Кнопка удаления справа от верхнего угла button-card */}
      <div
        className="button-card__delete"
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteButton(button._id, lastSegment);
        }}
      >
        X
      </div>
      {isPopupOpen && (
        <ButtonPopup
          button={button}
          onClose={togglePopup}
          handleUpdateButton={handleUpdateButton}
          handleCreateButton={handleCreateButton}
        />
      )}
    </div>
  );
};

export default ButtonCard;
