import "./UserCard.css";
import React, { useState } from "react";
import UserPopup from "../UserPopup/UserPopup.jsx";
import { formatDate } from "../../utils/utils.js";

const UserCard = ({ user, handleSubscription, handleDeleteUser, handleSignOut }) => {
  const { name, family, login, subscription, email, license } = user;
  const { status, start, end, ae, pp, mg, am, dr, gt } = subscription;

  const [isPopupOpen, setPopupOpen] = useState(false);
  const togglePopup = () => {
    console.log(license);
    console.log(user);
    setPopupOpen(!isPopupOpen);
  };

  // Создайте массив объектов, где каждый объект содержит ключ и значение
  const categories = [
    { key: "AE", value: ae },
    { key: "PP", value: pp },
    { key: "MG", value: mg },
    { key: "AM", value: am },
    { key: "DR", value: dr },
    { key: "GT", value: gt },
  ];

  return (
    <>
      <div className="user-card-wrapper">
        <li className="user-card" onClick={togglePopup}>
          <div className="user-card__main">
            <div className="user-card__header">
              <h4 className="user-card__title">{login}</h4>
              {/* <h4 className="user-card__title">{email}</h4> */}
              <div className="user-card__subscription_end">
                {status ? <>{formatDate(end[end.length - 1])}</> : null}
                <div
                  className={`user-card__status${
                    status
                      ? (new Date(end[end.length - 1]) -
                          new Date(start[start.length - 1])) /
                          (1000 * 60 * 60 * 24) === 14
                        ? " user-card__status_trial"
                        : " user-card__status_active"
                      : " user-card__status_disable"
                  }`}
                ></div>
              </div>
            </div>

            <div className="user-card__info">
              <div className="user-card__category user-card__item">
                {categories.map((category) =>
                  category.value ? (
                    <div key={category.key}>{category.key}</div>
                  ) : null
                )}
              </div>
            </div>
          </div>
          {/* Кнопка удаления справа от верхнего угла user-card */}
        </li>
        <div
          className="user-card__delete"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteUser(user._id);
          }}
        >
          X
        </div>
      </div>
      {isPopupOpen && (
        <UserPopup
          user={user}
          onClose={togglePopup}
          handleSubscription={handleSubscription}
          handleSignOut={handleSignOut}
        />
      )}
    </>
  );
};

export default UserCard;
