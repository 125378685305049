import mainApi from "../../utils/api.js";

const AddButtons = ({}) => {
  const aeButtons = [

  ];

  function handleCreateButton(buttonData) {
    mainApi
      .createButton(buttonData)
      .then((newButton) => {
        console.log(newButton)
        //setButtons((prevButtons) => [...prevButtons, newButton]);
        //setIsInfoTooltip({ isOpen: true, status: true, text: "Кнопка создана" });
      })
      .catch((err) => {
        console.log(err)
        //setIsInfoTooltip({ isOpen: true, status: false, text: err });
      });
  };

  const handleUpdateClick = (data) => {
    let updatedData = {};

    if (data.name) updatedData.name = data.name;
    if (data.script) updatedData.script = data.script;
    if (data.argument) updatedData.argument = data.argument;
    if (data.icon) updatedData.icon = data.icon;
    if (data.class) updatedData.htmlClass = data.class;

    if (data.help) {
      updatedData.help = {};
      if (data.help.eng) updatedData.help.eng = data.help.eng;
      if (data.help.ru) updatedData.help.ru = data.help.ru;
    }

    // Получаем последние два символа из container и записываем их в updatedData.category
    if (data.container) {
      updatedData.category = data.container.slice(-2);
    }

    handleCreateButton(updatedData);
  }

  function click(){
    aeButtons.forEach(buttonData => {
      handleUpdateClick(buttonData);
    })
  }

  return (
    <button onClick={click}>Запустить обработку</button>
  );
};

export default AddButtons;
