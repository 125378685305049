import { BASE_URL } from "./constants.js";

class Api {
  constructor({ baseUrl }) {
    this._baseUrl = baseUrl;
    this._headers = {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };
  }

  _fetchWithCheck(endpoint, config = {}) {
    return fetch(`${this._baseUrl}/${endpoint}`, {
      ...config,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("jwt")}`,
        ...config.headers,
      },
    }).then((response) => {
      return response.json().then((data) => {
        if (!response.ok) {
          throw new Error(data.message);
        }
        return data;
      });
    });
  }

  login({ email, password }) {
    return this._fetchWithCheck("signin", {
      method: "POST",
      body: JSON.stringify({ email, password }),
    });
  }

  updateSubscription(userId, data) {
    return this._fetchWithCheck(`users/${userId}`, {
      method: "PATCH",
      body: JSON.stringify(data),
    });
  }

  getUserInfo() {
    return this._fetchWithCheck("users/me");
  }

  getUsers() {
    return this._fetchWithCheck("users");
  }

  // Delete a user
  deleteUser(userId) {
    return this._fetchWithCheck(`users/${userId}`, {
      method: "DELETE",
    });
  }

  getButtons(type) {
    return this._fetchWithCheck(type);
  }

  // Create a new button
  createButton(buttonData, type) {
    return this._fetchWithCheck(type, {
      method: "POST",
      body: JSON.stringify(buttonData),
    });
  }

  // Update an existing button
  updateButton(buttonId, updatedData, type) {
    return this._fetchWithCheck(`${type}/${buttonId}`, {
      method: "PATCH",
      body: JSON.stringify(updatedData),
    });
  }

  // Delete a button
  deleteButton(buttonId, type) {
    return this._fetchWithCheck(`${type}/${buttonId}`, {
      method: "DELETE",
    });
  }
}

const mainApi = new Api({
  baseUrl: BASE_URL,
});

export default mainApi;
