import "./App.css";
import mainApi from "../../utils/api.js";
import CurrentUserContext from "../../contexts/CurrentUserContext.jsx";
import { useState, useEffect } from "react";
import {
  Navigate,
  useRoutes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Navigation from "../Navigation/Navigation.jsx";
import Subscribers from "../Subscribers/Subscribers.jsx";
import UserList from "../UserList/UserList.jsx";
import ButtonList from "../ButtonList/ButtonList.jsx";
import OtherButtonList from "../OtherButtonList/OtherButtonList.jsx";
import AddButtons from "../AddButton/AddButton.jsx";

import Login from "../Login/Login.jsx";
import InfoTooltip from "../InfoTooltip/InfoTooltip.jsx";
import Preloader from "../Preloader/Preloader.jsx";

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const [buttons, setButtons] = useState([]);
  const [ppButtons, ppSetButtons] = useState([]);
  const [aiButtons, aiSetButtons] = useState([]);
  const [psButtons, psSetButtons] = useState([]);

  const [isButtonsUpdated, setIsButtonsUpdated] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);

  const [isLoader, setIsLoader] = useState(false);
  const [isInfoTooltip, setIsInfoTooltip] = useState({
    isOpen: false,
    status: true,
    text: "",
  });

  const [load, setLoad] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [serverError, setServerError] = useState(null);
  const [users, setUsers] = useState([]);
  const [isUsersUpdated, setIsUsersUpdated] = useState(false);

  function closeInfoTooltip() {
    setIsInfoTooltip({ ...isInfoTooltip, isOpen: false });
  }

  function handleLogin({ email, password }) {
    setIsLoader(true);
    mainApi
      .login({ email, password })
      .then((jwt) => {
        if (jwt.token) {
          localStorage.setItem("jwt", jwt.token);

          // Получаем информацию о пользователе после авторизации
          return mainApi.getUserInfo();
        }
        throw new Error("Token is not present in response");
      })
      .then((userData) => {
        // Проверяем роль пользователя
        if (userData.role !== "admin") {
          // Удаляем токен, так как у пользователя нет прав админа
          localStorage.removeItem("jwt");
          setLoggedIn(false);
          throw new Error("You are not authorized to access this application");
        }

        // Если все в порядке, устанавливаем статус входа в систему и текущего пользователя
        setCurrentUser(userData);
        setLoggedIn(true);
        navigate("/");
      })
      .catch((err) => {
        setServerError(err.message);
      })
      .finally(() => setIsLoader(false));
  }

  function handleSignOut(userId) {
    mainApi
      .updateSubscription(userId, {
        license: {
          status: false,
          hostName: "",
          userName: "",
        },
      })
      .then((response) => {
        setIsUsersUpdated(true);
        setIsInfoTooltip({
          isOpen: true,
          status: true,
          text: "Лицензия деактивированна",
        });
      })
      .catch((err) => {
        setIsInfoTooltip({ isOpen: true, status: false, text: err });
      });
  }

  function handleSubscription(userId, updateData) {
    mainApi
      .updateSubscription(userId, updateData)
      .then((updatedUser) => {
        setIsUsersUpdated(true);
        setIsInfoTooltip({
          isOpen: true,
          status: true,
          text: "Подписка Обновлена",
        });
      })
      .catch((err) => {
        setIsInfoTooltip({ isOpen: true, status: false, text: err });
      });
  }

  function handleDeleteUser(userId) {
    mainApi
      .deleteUser(userId)
      .then(() => {
        setUsers((prevUser) => prevUser.filter((user) => user._id !== userId));
        setIsInfoTooltip({
          isOpen: true,
          status: true,
          text: "Пользователь удален",
        });
      })
      .catch((err) => {
        setIsInfoTooltip({ isOpen: true, status: false, text: err });
      });
  }

  function handleCreateButton(buttonData, type) {
    mainApi
      .createButton(buttonData, type)
      .then((newButton) => {
        if (type === 'buttons') {
          setButtons((prevButtons) => [...prevButtons, newButton]);
        } else if (type === 'ppbuttons') {
          ppSetButtons((prevButtons) => [...prevButtons, newButton]);
        } else if (type === 'aibuttons') {
          aiSetButtons((prevButtons) => [...prevButtons, newButton]);
        } else if (type === 'psbuttons') {
          psSetButtons((prevButtons) => [...prevButtons, newButton]);
        };
        setIsInfoTooltip({
          isOpen: true,
          status: true,
          text: "Кнопка создана",
        });
      })
      .catch((err) => {
        setIsInfoTooltip({ isOpen: true, status: false, text: err });
      });
  }

  function handleUpdateButton(buttonId, updatedData, type) {
    mainApi
      .updateButton(buttonId, updatedData, type)
      .then((updatedButton) => {
        if (type === 'buttons') {
          setButtons((prevButtons) =>
            prevButtons.map((button) =>
              button._id === buttonId ? updatedButton : button
            )
          );
        } else if (type === 'ppbuttons') {
          ppSetButtons((prevButtons) =>
            prevButtons.map((button) =>
              button._id === buttonId ? updatedButton : button
            )
          );
        } else if (type === 'aibuttons') {
          aiSetButtons((prevButtons) =>
            prevButtons.map((button) =>
              button._id === buttonId ? updatedButton : button
            )
          );
        } else if (type === 'psbuttons') {
          psSetButtons((prevButtons) =>
            prevButtons.map((button) =>
              button._id === buttonId ? updatedButton : button
            )
          );
        };
        setIsInfoTooltip({
          isOpen: true,
          status: true,
          text: "Кнопка обновлена",
        });
      })
      .catch((err) => {
        setIsInfoTooltip({ isOpen: true, status: false, text: err });
      });
  }

  function handleDeleteButton(buttonId, type) {
    mainApi
      .deleteButton(buttonId, type)
      .then(() => {
        if (type === 'buttons') {
          setButtons((prevButtons) =>
            prevButtons.filter((button) => button._id !== buttonId)
          );
        } else if (type === 'ppbuttons') {
          ppSetButtons((prevButtons) =>
            prevButtons.filter((button) => button._id !== buttonId)
          );
        } else if (type === 'aibuttons') {
          aiSetButtons((prevButtons) =>
            prevButtons.filter((button) => button._id !== buttonId)
          );
        } else if (type === 'psbuttons') {
          psSetButtons((prevButtons) =>
            prevButtons.filter((button) => button._id !== buttonId)
          );
        };
        setIsInfoTooltip({
          isOpen: true,
          status: true,
          text: "Кнопка удалена",
        });
      })
      .catch((err) => {
        setIsInfoTooltip({ isOpen: true, status: false, text: err });
      });
  }

  useEffect(() => {
    const jwt = localStorage.getItem("jwt");

    const checkAuthorization = async () => {
      try {
        setIsLoader(true);
        const userData = await mainApi.getUserInfo();
        if (userData.role !== "admin") {
          // Удаляем токен и устанавливаем setLoggedIn в false, так как у пользователя нет прав админа
          localStorage.removeItem("jwt");
          setLoggedIn(false);
          throw new Error("You are not authorized to access this application");
        }

        setCurrentUser(userData);
        setLoggedIn(true);
        setAuthChecked(true);
        if (location.pathname === "/signin") {
          navigate("/"); // Переадресация на главную страницу, если пользователь находится на странице входа
        }
      } catch (err) {
        setIsInfoTooltip({ isOpen: true, status: false, text: err.message });
        setAuthChecked(true);
      } finally {
        setIsLoader(false);
      }
    };

    if (jwt) {
      checkAuthorization();
    } else {
      setAuthChecked(true);
    }
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoader(true);
        const fetchedUsers = await mainApi.getUsers();
        setUsers(fetchedUsers);
        setIsUsersUpdated(false); // Сбросьте флаг после успешного обновления списка пользователей
      } catch (err) {
        setIsInfoTooltip({ isOpen: true, status: false, text: err });
      } finally {
        setIsLoader(false);
      }
    };

    if (loggedIn) {
      fetchUsers();
    }
  }, [loggedIn, isUsersUpdated]);

  useEffect(() => {
    const fetchMainButtons = async () => {
      try {
        setIsLoader(true);
        const fetchedButtons = await mainApi.getButtons("buttons");
        setButtons(fetchedButtons);
        setIsButtonsUpdated(false); // Reset the flag after successfully fetching the buttons
      } catch (err) {
        setIsInfoTooltip({ isOpen: true, status: false, text: err });
      } finally {
        setIsLoader(false);
      }
    };

    const fetchPPButtons = async () => {
      try {
        setIsLoader(true);
        const fetchedButtons = await mainApi.getButtons("ppbuttons");
        ppSetButtons(fetchedButtons);
        setIsButtonsUpdated(false); // Reset the flag after successfully fetching the buttons
      } catch (err) {
        setIsInfoTooltip({ isOpen: true, status: false, text: err });
      } finally {
        setIsLoader(false);
      }
    };

    const fetchAIButtons = async () => {
      try {
        setIsLoader(true);
        const fetchedButtons = await mainApi.getButtons("aibuttons");
        aiSetButtons(fetchedButtons);
        setIsButtonsUpdated(false); // Reset the flag after successfully fetching the buttons
      } catch (err) {
        setIsInfoTooltip({ isOpen: true, status: false, text: err });
      } finally {
        setIsLoader(false);
      }
    };

    const fetchPSButtons = async () => {
      try {
        setIsLoader(true);
        const fetchedButtons = await mainApi.getButtons("psbuttons");
        psSetButtons(fetchedButtons);
        setIsButtonsUpdated(false); // Reset the flag after successfully fetching the buttons
      } catch (err) {
        setIsInfoTooltip({ isOpen: true, status: false, text: err });
      } finally {
        setIsLoader(false);
      }
    };

    if (loggedIn) {
      setLoad(true)
      fetchMainButtons();
      fetchPPButtons();
      fetchAIButtons();
      fetchPSButtons();
      setLoad(false)
    }
  }, [loggedIn, isButtonsUpdated]);

  const routing = useRoutes([
    {
      path: "signin",
      element: authChecked ? (
        loggedIn ? (
          <Navigate to="/" />
        ) : (
          <Login handleLogin={handleLogin} serverError={serverError} />
        )
      ) : null,
    },
    {
      path: "/",
      element: authChecked ? (
        loggedIn ? (
          <>
            <Navigation />
            <Subscribers users={users} />
            <UserList
              users={users}
              handleSubscription={handleSubscription}
              handleDeleteUser={handleDeleteUser}
              handleSignOut={handleSignOut}
            />
          </>
        ) : (
          <Navigate to="/signin" />
        )
      ) : null,
    },
    {
      path: "buttons",
      element: authChecked ? (
        loggedIn ? (
          <>
            <Navigation />
            <ButtonList
              buttons={buttons}
              handleCreateButton={handleCreateButton}
              handleUpdateButton={handleUpdateButton}
              handleDeleteButton={handleDeleteButton}
            />
            <AddButtons />
          </>
        ) : (
          <Navigate to="/signin" />
        )
      ) : null,
    },
    {
      path: "ppbuttons",
      element: authChecked ? (
        loggedIn ? (
          <>
            <Navigation />
            <OtherButtonList
              buttons={ppButtons}
              handleCreateButton={handleCreateButton}
              handleUpdateButton={handleUpdateButton}
              handleDeleteButton={handleDeleteButton}
            />
          </>
        ) : (
          <Navigate to="/signin" />
        )
      ) : null,
    },
    {
      path: "aibuttons",
      element: authChecked ? (
        loggedIn ? (
          <>
            <Navigation />
            <OtherButtonList
              buttons={aiButtons}
              handleCreateButton={handleCreateButton}
              handleUpdateButton={handleUpdateButton}
              handleDeleteButton={handleDeleteButton}
            />
          </>
        ) : (
          <Navigate to="/signin" />
        )
      ) : null,
    },
    {
      path: "psbuttons",
      element: authChecked ? (
        loggedIn ? (
          <>
            <Navigation />
            <OtherButtonList
              buttons={psButtons}
              handleCreateButton={handleCreateButton}
              handleUpdateButton={handleUpdateButton}
              handleDeleteButton={handleDeleteButton}
            />
          </>
        ) : (
          <Navigate to="/signin" />
        )
      ) : null,
    },
  ]);

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div>
        <InfoTooltip onClose={closeInfoTooltip} isInfoTooltip={isInfoTooltip} />
        {isLoader ? <Preloader isOpen={!load} /> : routing}
      </div>
    </CurrentUserContext.Provider>
  );
}
