import "./ButtonList.css";
import ButtonCard from "../ButtonCard/ButtonCard.jsx";
import ButtonPopup from "../ButtonPopup/ButtonPopup.jsx";
import { useState, useMemo } from "react";
import { CATEGORIES } from "../../utils/constants.js";

const ButtonList = ({
  buttons,
  handleUpdateButton,
  handleCreateButton,
  handleDeleteButton,
}) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("ae");

  const handleShowPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const filteredButtons = useMemo(() => {
    const filtered = selectedCategory
      ? buttons.filter((button) => button.category === selectedCategory)
      : buttons;

    return filtered.slice().sort((a, b) => a.name.localeCompare(b.name));
  }, [buttons, selectedCategory]);

  return (
    <section className="buttons-card">
      {/* Кнопки категорий */}
      <div className="buttons-nav">
        {CATEGORIES.map((category) => (
          <div
            key={category}
            className={`buttons-link ${
              selectedCategory === category ? "active" : ""
            }`}
            onClick={() => handleCategorySelect(category)}
          >
            {category.toUpperCase()}
          </div>
        ))}
      </div>

      {filteredButtons.length ? (
        <ul className="buttons-card__list">
          {filteredButtons.map((data) => (
            <ButtonCard
              key={data._id}
              button={data}
              handleUpdateButton={handleUpdateButton}
              handleDeleteButton={handleDeleteButton}
            />
          ))}
        </ul>
      ) : null}
      <div className="add-button" onClick={handleShowPopup}>
        +
      </div>
      {isPopupVisible && (
        <ButtonPopup
          onClose={handleClosePopup}
          handleCreateButton={handleCreateButton}
        />
      )}
    </section>
  );
};

export default ButtonList;
