import React, { useState, useEffect } from "react";
import "./Invoice.css";
import { addMonths, format } from "date-fns";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const Invoice = ({ user, categories, time }) => {
  const order = ["AE", "PP + MG", "AM", "DR"];

  const [invoiceNumber, setInvoiceNumber] = useState("");

  async function generateInvoiceNumber(userId) {
    const encoder = new TextEncoder();
    const currentDate = new Date().toISOString();
    const dataToHash = encoder.encode(`${userId}-${currentDate}`);

    const hashArray = await window.crypto.subtle.digest("SHA-256", dataToHash);

    const hashHex = Array.from(new Uint8Array(hashArray))
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");

    const shortHash = hashHex.substr(0, 10);
    return shortHash;
  }

  const downloadPDF = () => {
    const input = document.querySelector(".invoice");
    const downloadBtn = document.querySelector(".invoice__download-btn");

    // Скрыть кнопку перед созданием изображения
    downloadBtn.style.display = "none";

    html2canvas(input)
      .then((canvas) => {
        // Вернуть кнопку после создания изображения, но до создания PDF
        downloadBtn.style.display = "block";

        const imgData = canvas.toDataURL("image/jpeg"); // Конвертация в JPEG
        const pdf = new jsPDF("p", "mm", [200.67, 181.34]);

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight); // Использование JPEG

        // Формирование имени файла
        const currentDate = format(new Date(), "yyyy_MM_dd");
        const fileName = `invoice_${user.name}_${user.family}_${currentDate}.pdf`;
        pdf.save(fileName);
      })
      .catch((err) => {
        // Если что-то пошло не так, возвращаем кнопку обратно
        downloadBtn.style.display = "block";
        console.error("Error while generating the PDF: ", err);
      });
  };

  useEffect(() => {
    generateInvoiceNumber(user._id).then((number) => {
      setInvoiceNumber(number);
    });
  }, [user._id]);

  switch (time) {
    case "Month":
      time = 1
      break;
    case "2 Month":
      time = 2
      break;
    case "Year":
      time = 12
      break;
    default:
      break;
  }

  const currentDate = format(new Date(), "yyyy-MM-dd");
  const subscriptionEndDate = format(addMonths(new Date(), time), "yyyy-MM-dd");

  const prices = {
    AE: 25 * time,
    PP: 25 * time,
    MG: 25 * time,
    AM: 25 * time,
    DR: 25 * time,
    GT: 25 * time
  };

  let activeCategories = categories.filter((category) => category.value);

  // Рассчитываем субтотал
  const subtotal = activeCategories.reduce(
    (total, cat) => total + prices[cat.key],
    0
  );

  // Определяем скидку
  let discount = 0;
  if (activeCategories.length === categories.length - 1) {
    // Если выбраны все категории
    discount = (25 * time * activeCategories.length) - (10 * time * activeCategories.length);
  } else if (activeCategories.length > 1) {
    // Если выбрано больше одной категории
    discount = (25 * time * activeCategories.length) - (15 * time * activeCategories.length);
  }

  const total = subtotal - discount;

  return (
    <div className="invoice">
      <div className="invoice__header">
        <div className="invoice__user-info">
          <h2 className="invoice__user-name">Timur Kharchenko</h2>
          <h4 className="invoice__user-email">work.solomon@gmail.com</h4>
        </div>
        <div className="invoice__amount-due-container">
          <h5 className="invoice__amount-due">Amount Due:</h5>
          <h3 className="invoice__amount-total">$ {total}</h3>
        </div>
      </div>

      <div className="invoice__billed">
        <div className="invoice__billed-info">
          <div className="invoice__title">Billed to:</div>
          <div className="invoice__login">{user.name + " " + user.family}</div>
          <div className="invoice__email">{user.email}</div>
        </div>
        <div className="invoice__details">
          <div className="invoice__details-item">
            <div className="invoice__title">Invoice Number:</div>
            <div className="invoice__number">{invoiceNumber}</div>
          </div>

          <div className="invoice__details-item">
            <div className="invoice__title">Date of issue:</div>
            <div className="invoice__date">{currentDate}</div>
          </div>
        </div>
      </div>

      <div className="invoice__categories">
        <div className="invoice__category-header">
          <div className="invoice__title">SUBSCRIPTION</div>
          <div className="invoice__title">PRICE</div>
        </div>
        <div className="invoice__period">Until {subscriptionEndDate}</div>

        {activeCategories.map((category) => (
          <div className="invoice__category" key={category.key}>
            <div className="invoice__category-description">{category.key}</div>
            <div className="invoice__category-price">
              $ {prices[category.key]}
            </div>
          </div>
        ))}
      </div>

      <div className="invoice__summary">
        <div className="invoice__subtotal">
          <div className="invoice__title">SUBTOTAL:</div>
          <div>$ {subtotal}</div>
        </div>
        <div className="invoice__subtotal">
          <div className="invoice__title">DISCOUNT: </div>
          <div>$ {discount}</div>
        </div>
        <div className="invoice__subtotal">
          <div className="invoice__title">TOTAL: </div>
          <div>$ {total}</div>
        </div>
      </div>

      <button className="invoice__download-btn" onClick={downloadPDF}>
        Download
      </button>
    </div>
  );
};

export default Invoice;
