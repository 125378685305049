import ButtonCard from "../ButtonCard/ButtonCard.jsx";
import ButtonPopup from "../ButtonPopup/ButtonPopup.jsx";
import { useState } from "react";

const OtherButtonList = ({
  buttons,
  handleUpdateButton,
  handleCreateButton,
  handleDeleteButton,
}) => {
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleShowPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  // Сортировка кнопок по имени
  const sortedButtons = buttons.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <section className="buttons-card">
      <ul className="buttons-card__list">
        {sortedButtons.map((data) => (
          <ButtonCard
            key={data._id}
            button={data}
            handleUpdateButton={handleUpdateButton}
            handleDeleteButton={handleDeleteButton}
          />
        ))}
      </ul>
      <div className="add-button" onClick={handleShowPopup}>
        +
      </div>
      {isPopupVisible && (
        <ButtonPopup
          onClose={handleClosePopup}
          handleCreateButton={handleCreateButton}
        />
      )}
    </section>
  );
};

export default OtherButtonList;
