import "./Navigation.css";
import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
  return (
      <div className="nav-container">
          <NavLink
              to="/"
              className="nav-link"
          >
              Users
          </NavLink>
          <NavLink
              to="/buttons"
              className="nav-link"
          >
              AE
          </NavLink>
          <NavLink
              to="/ppbuttons"
              className="nav-link"
          >
              PP
          </NavLink>
          <NavLink
              to="/aibuttons"
              className="nav-link"
          >
              AI
          </NavLink>
          <NavLink
              to="/psbuttons"
              className="nav-link"
          >
              PS
          </NavLink>
      </div>
  );
}

export default Navigation;
